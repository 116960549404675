import { MrbBaseViewStore } from "mrb/core";
import { observable, makeObservable, action, computed } from "mobx";
import { UpdatePodcastStatusOptions, UpdateStationStatusOptions } from "application/common/models";
import { some, findIndex, pullAt, forEach, isNil } from "lodash";
import { podcastType } from "common/constants";

class HomeManageSelectedItemsViewStore extends MrbBaseViewStore {
    @observable isBulk = false;
    @observable isUpdating = false;
    @observable selectedItems = [];

    @computed get isSelectedItemsAny() {
        return this.selectedItems.length > 0;
    }

    constructor(rootStore, homeViewStore) {
        super(rootStore);
        makeObservable(this);
        this.homeViewStore = homeViewStore;
        this.routeStore = homeViewStore.routeStore;
        this.selectedItems = [];
    }

    @action.bound
    changeIsBulk() {
        if (this.isUpdating) {
            return;
        }
        this.isBulk = !this.isBulk;
        if (!this.isBulk) {
            this.selectedItems = [];
        }
    }

    @action.bound
    selectItem(item) {
        if (this.isUpdating) {
            return;
        }
        let selectedItem = {
            stationId: item.stationId,
            podcastId: item.podcastId,
            title: item.title,
            isPremium: item.isPremium,
            isVisibleInApp: item.isVisibleInApp,
        };
        if (!some(this.selectedItems, selectedItem)) {
            let contentAccessResult = null;
            if (!isNil(item.stationId)) {
                contentAccessResult = this.rootStore.contentAccessValidator.canAccessStation({
                    isPremium: item.isPremium,
                    isLegacySubscriptionValid: item.validLegacySubscription,
                    unavailableInMobileApp: item.isVisibleInApp === false,
                });
            } else {
                contentAccessResult = this.rootStore.contentAccessValidator.canAccessPodcast({
                    isPremium: item.isPremium,
                    isLegacySubscriptionValid: item.validLegacySubscription,
                    unavailableInMobileApp: item.isVisibleInApp === false,
                });
            }
            if (contentAccessResult.canAccess) {
                if (item.podcastType !== podcastType.podcastGroup) {
                    this.selectedItems.push(selectedItem);
                }
            }
        } else {
            let indexOfElement = findIndex(
                this.selectedItems,
                function (o) {
                    return o.stationId === item.stationId && o.podcastId === item.podcastId;
                },
                0
            );
            pullAt(this.selectedItems, indexOfElement);
        }
    }

    @action.bound
    async changeTurnOn(value) {
        const stationOptions = [];
        const podcastOptions = [];
        forEach(this.selectedItems, function (item) {
            if (item.stationId) {
                stationOptions.push(
                    new UpdateStationStatusOptions(
                        item.stationId,
                        item.title,
                        item.isPremium,
                        item.isVisibleInApp === false
                    )
                );
            }
            if (item.podcastId) {
                podcastOptions.push(
                    new UpdatePodcastStatusOptions(
                        item.podcastId,
                        item.title,
                        item.isPremium,
                        item.isVisibleInApp === false
                    )
                );
            }
        });
        if (!this.isUpdating) {
            if (this.selectedItems.length > 0) {
                this.setIsUpdating(true);
                try {
                    this.rootStore.mainLoaderStore.suspend();
                    await this.rootStore.userContentManagerStore.updateStationsAndPodcastsStatus(
                        stationOptions,
                        podcastOptions,
                        value
                    );
                    this.homeViewStore.changeDeviceSelectedItemsStatus(this.selectedItems, value);
                } catch (err) {
                    this.rootStore.notificationStore.error(
                        "Unexpected error occurred. Please contact the support team.",
                        err
                    );
                } finally {
                    this.setIsUpdating(false);
                    this.changeIsBulk();
                    this.rootStore.mainLoaderStore.resume();
                }
            }
        }
    }

    @action
    setIsUpdating(isUpdating) {
        this.isUpdating = isUpdating;
    }
}

export default HomeManageSelectedItemsViewStore;
