import React, { useState, useCallback } from "react";
import ContentLoader from "react-content-loader";

const UploadedImageLoaderTemplate = (size, props) => {
    const [loaderWidth, setLoaderWidth] = useState(0);
    const isFullWidth = size.size === "c-upload__upload--full";

    const cardHeight = size.size === "c-upload__upload--sml" ? 235 : 360;
    const cardWidth = size.size === "c-upload__upload--sml" ? 200 : 360;

    const ref = useCallback((node) => {
        if (node !== null) setLoaderWidth(node.getBoundingClientRect().width);
    }, []);

    return (
        <div className="u-display--flex u-display--flex--jc--center u-mar--bottom--tny" ref={ref}>
            <ContentLoader
                speed={2}
                width={isFullWidth ? loaderWidth : cardWidth}
                height="100%"
                viewBox={`0 0 ${isFullWidth ? loaderWidth : cardWidth} ${cardHeight}`}
                backgroundColor="#262729"
                foregroundColor="#2b2b2b"
                {...props}
            >
                <rect x="0" y="0" rx="6" ry="6" width="100%" height="100%" />
            </ContentLoader>
        </div>
    );
};

export default UploadedImageLoaderTemplate;
