import { computed, makeObservable } from "mobx";
import { nakiRadioSystemTypes, subscriptionStatuses, subscriptionPlans } from "common/constants";
import { nakiRadioContentType, ContentAccessResult } from "application/common/content-access";
import { isNil, find } from "lodash";

const CONTENT_ACCESS_MAP = {};
const premiumContentAccess = [
    {
        contentType: nakiRadioContentType.podcast,
        deviceSystemType: nakiRadioSystemTypes.venice,
    },
    {
        contentType: nakiRadioContentType.station,
        deviceSystemType: nakiRadioSystemTypes.venice,
    },
    {
        contentType: nakiRadioContentType.nakiNigun,
        deviceSystemType: nakiRadioSystemTypes.venice,
    },
    {
        contentType: nakiRadioContentType.podcast,
        deviceSystemType: nakiRadioSystemTypes.mobile,
    },
    {
        contentType: nakiRadioContentType.station,
        deviceSystemType: nakiRadioSystemTypes.mobile,
    },
    {
        contentType: nakiRadioContentType.nakiNigun,
        deviceSystemType: nakiRadioSystemTypes.mobile,
    },
];
CONTENT_ACCESS_MAP[subscriptionPlans.nakiRadioPremium] = premiumContentAccess;
CONTENT_ACCESS_MAP[subscriptionPlans.subscriptionBundleSolo] = premiumContentAccess;
CONTENT_ACCESS_MAP[subscriptionPlans.subscriptionBundleDuo] = premiumContentAccess;
CONTENT_ACCESS_MAP[subscriptionPlans.subscriptionBundleNaki23Black] = premiumContentAccess;

CONTENT_ACCESS_MAP[subscriptionPlans.nakiNigun] = [
    {
        contentType: nakiRadioContentType.nakiNigun,
        deviceSystemType: nakiRadioSystemTypes.mobile,
    },
    {
        contentType: nakiRadioContentType.nakiNigun,
        deviceSystemType: nakiRadioSystemTypes.venice,
    },
];

class ContentAccessValidator {
    @computed get selectedDevice() {
        return this.userDeviceStore.selectedDevice;
    }

    @computed get subscriptions() {
        return this.premiumSubscriptionStore.subscriptions;
    }

    constructor(rootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
        this.userDeviceStore = this.rootStore.userDeviceStore;
        this.premiumSubscriptionStore = this.rootStore.premiumSubscriptionStore;
    }

    canAccessPodcast(options) {
        return this._canAccessContent(options, nakiRadioContentType.podcast);
    }

    canAccessStation(options) {
        return this._canAccessContent(options, nakiRadioContentType.station);
    }

    canAccessNakiNigun(options) {
        return this._canAccessContent(options, nakiRadioContentType.nakiNigun);
    }

    _canAccessContent(options, contentType) {
        if (!this._validateParameters(options)) {
            throw new Error("Invalid options parameters.");
        }
        const result = new ContentAccessResult();
        if (options.unavailableInMobileApp) {
            if (!isNil(this.selectedDevice)) {
                result.canAccessOnSelectedDevice =
                    this.selectedDevice.device.systemType.abrv !== nakiRadioSystemTypes.mobile;
            }
        }

        if (!options.isPremium) {
            result.canAccessPremium = true;
        } else {
            result.canAccessPremium = this._canAccessPremiumContent(options, contentType);
        }

        return result;
    }

    _canAccessPremiumContent(options, contentType) {
        if (isNil(this.selectedDevice)) {
            return false;
        }

        for (let subscription of this.subscriptions) {
            const isSubscriptionValid = this._validateSubscription(subscription);
            if (!isSubscriptionValid && !this._validateLegacySubscription(options)) {
                continue;
            }

            const subscriptionAccessRules = CONTENT_ACCESS_MAP[subscription.product.slug];
            if (subscriptionAccessRules) {
                const canAccess = find(
                    subscriptionAccessRules,
                    (rule) =>
                        rule.contentType === contentType &&
                        rule.deviceSystemType === this.selectedDevice.device.systemType.abrv
                );
                if (canAccess) {
                    return true;
                }
            }
        }

        return false;
    }

    _validateParameters(options) {
        if (isNil(options)) {
            throw new Error("options parameter is required");
        }

        return true;
    }

    _validateSubscription(subscription) {
        if (isNil(subscription)) {
            return false;
        }

        switch (subscription.subscriptionStatus.abrv) {
            case subscriptionStatuses.active:
            case subscriptionStatuses.pastDue:
            case subscriptionStatuses.trial:
                return true;
            default:
                return false;
        }
    }

    _validateLegacySubscription(options) {
        if (isNil(options)) {
            return false;
        }
        if (!isNil(options.isLegacySubscriptionValid)) {
            return options.isLegacySubscriptionValid;
        }
        if (!isNil(options.legacySubscriptionExpirationDate)) {
            return this.premiumSubscriptionStore.isLegacySubscriptionActive(options.legacySubscriptionExpirationDate);
        }

        return false;
    }
}

export default ContentAccessValidator;
