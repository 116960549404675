import { action, computed, makeObservable } from "mobx";
import { BaseHomePreviewViewStore } from "application/home/stores";
import { UpdatePodcastStatusOptions } from "application/common/models";
import { isNil } from "lodash";

class BaseHomePodcastViewStore extends BaseHomePreviewViewStore {
    @computed get coverImageUrl() {
        if (this.item && this.item.coverImageId) {
            return this.routeStore.createPodcastCoverImageUrl(this.item.coverImageId, 200, 200);
        }

        return null;
    }

    constructor(rootStore, { routeStore }) {
        const id = rootStore.routerStore.routerState.params.id;
        super(rootStore, {
            id: id,
            navigateBack: "master.application.home.list",
        });
        makeObservable(this);
        this.routeStore = routeStore;
    }

    async get(id) {
        return this.routeStore.getPodcast(id, this.rootStore.userDeviceStore.selectedDevice.id);
    }

    setItem(item) {
        super.setItem(item);
        this.statusItem = item.userDevicePodcast;
    }

    @action.bound
    async updateTurnedOnStatus(isTurnedOn) {
        try {
            this.setUpdatingStatus(true);
            const options = new UpdatePodcastStatusOptions(
                this.item.id,
                this.item.title,
                this.item.isPremium,
                this.item.isVisibleInApp === false
            );
            await this.rootStore.userContentManagerStore.updatePodcastStatus(options, isTurnedOn);
            this.setStatusItem({ isTurnedOn: isTurnedOn });
        } catch (err) {
            this.rootStore.notificationStore.error("Unexpected error occurred. Please contact the support team.", err);
        } finally {
            this.setUpdatingStatus(false);
        }
    }

    getStatusItem(deviceId, resourceId) {
        throw new Error("Method not implemented.");
    }

    setStatusItem(statusItem) {
        throw new Error("Method not implemented.");
    }

    evaluateContentAccess(item) {
        if (isNil(item)) {
            return false;
        }
        return this.rootStore.contentAccessValidator.canAccessPodcast({
            isPremium: item.isPremium,
            isLegacySubscriptionValid: item.validLegacySubscription,
            unavailableInMobileApp: item.isVisibleInApp === false,
        });
    }
}

export default BaseHomePodcastViewStore;
