import { BaseService } from "mrb/core";
import { StationApiRouteService } from "common/services";
import { findParams } from "mrb/core/utils";

class StationService extends BaseService {
    constructor(apiClient) {
        super(apiClient, new StationApiRouteService());
    }

    findShufflableStation(filter, abortSignal = null) {
        const params = findParams(filter);
        const data = {
            embed: params.embed,
            excludeIds: params.excludeIds,
            fields: params.fields,
            page: params.page,
            rpp: params.rpp,
            searchQuery: params.searchQuery,
            sort: params.sort,
        };
        const url = this.routeService.findShufflableStation(data);
        return this.apiClient.post(url, data, null, abortSignal);
    }

    updateActiveStatus(id, value, abortSignal = null) {
        const url = this.routeService.updateActiveStatus(id, value);
        return this.apiClient.put(url, null, null, abortSignal);
    }

    updateOnByDefaultStatus(id, value, abortSignal = null) {
        const url = this.routeService.updateOnByDefaultStatus(id, value);
        return this.apiClient.put(url, null, null, abortSignal);
    }

    updateOnByDefaultInAppStatus(id, value, abortSignal = null) {
        const url = this.routeService.updateOnByDefaultInAppStatus(id, value);
        return this.apiClient.put(url, null, null, abortSignal);
    }

    updatePremiumStatus(id, value, abortSignal = null) {
        const url = this.routeService.updatePremiumStatus(id, value);
        return this.apiClient.put(url, null, null, abortSignal);
    }

    updateVisibilityStatus(id, value, abortSignal = null) {
        const url = this.routeService.updateVisibilityStatus(id, value);
        return this.apiClient.put(url, null, null, abortSignal);
    }

    updateStationTurnedOnStatus(deviceId, stationId, value, abortSignal = null) {
        const url = this.routeService.updateStationTurnedOnStatus(deviceId, stationId, value);
        return this.apiClient.put(url, null, null, abortSignal);
    }

    updateAppVisibilityStatus(id, value, abortSignal = null) {
        const url = this.routeService.updateAppVisibilityStatus(id, value);
        return this.apiClient.put(url, null, null, abortSignal);
    }

    updateFeaturedStatus(id, value, abortSignal = null) {
        const url = this.routeService.updateFeaturedStatus(id, value);
        return this.apiClient.put(url, null, null, abortSignal);
    }

    findDeviceStations(filter, abortSignal = null) {
        const url = this.routeService.findDeviceStations(filter);
        return this.apiClient.get(url, null, abortSignal);
    }
}

export default StationService;
