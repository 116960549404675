import { BaseService } from "mrb/core";
import { NakiNigunAlbumApiRouteService } from "common/services";
import { findParams } from "mrb/core/utils";

class NakiNigunAlbumService extends BaseService {
    constructor(apiClient) {
        super(apiClient, new NakiNigunAlbumApiRouteService());
    }

    findShufflableNakiNigunAlbum(filter, abortSignal = null) {
        const params = findParams(filter);
        const data = {
            embed: params.embed,
            excludeIds: params.excludeIds,
            fields: params.fields,
            page: params.page,
            rpp: params.rpp,
            searchQuery: params.searchQuery,
            sort: params.sort,
        };
        const url = this.routeService.findShufflableNakiNigunAlbum(data);
        return this.apiClient.post(url, data, null, abortSignal);
    }

    findSongs(albumId, filter, abortSignal = null) {
        const url = this.routeService.findSongs(albumId, filter);
        return this.apiClient.get(url, null, abortSignal);
    }

    deleteAlbumTrack(id, abortSignal = null) {
        const url = this.routeService.deleteAlbumTrack(id);
        return this.apiClient.delete(url, null, null, abortSignal);
    }

    getAllSongs(id, options = {}, abortSignal = null) {
        const url = this.routeService.getAllSongs(id, options);
        return this.apiClient.get(url, null, abortSignal);
    }

    updateActiveStatus(id, value, abortSignal = null) {
        const url = this.routeService.updateActiveStatus(id, value);
        return this.apiClient.put(url, null, null, abortSignal);
    }

    updatePremiumStatus(id, value, abortSignal = null) {
        const url = this.routeService.updatePremiumStatus(id, value);
        return this.apiClient.put(url, null, null, abortSignal);
    }

    updateOnByDefaultStatus(id, value, abortSignal = null) {
        const url = this.routeService.updateOnByDefaultStatus(id, value);
        return this.apiClient.put(url, null, null, abortSignal);
    }

    updateOnByDefaultInAppStatus(id, value, abortSignal = null) {
        const url = this.routeService.updateOnByDefaultInAppStatus(id, value);
        return this.apiClient.put(url, null, null, abortSignal);
    }

    updateFeaturedStatus(id, value, abortSignal = null) {
        const url = this.routeService.updateFeaturedStatus(id, value);
        return this.apiClient.put(url, null, null, abortSignal);
    }

    updateHlsEnabledStatus(id, value, abortSignal = null) {
        const url = this.routeService.updateHlsEnabledStatus(id, value);
        return this.apiClient.put(url, null, null, abortSignal);
    }

    editAlbumTrackTitle(id, resources, abortSignal = null) {
        const url = this.routeService.editAlbumTrackTitle(id, resources);
        return this.apiClient.put(url, resources, null, abortSignal);
    }

    uploadAlbumTrack(albumId, formData, abortSignal = null) {
        const url = this.routeService.uploadAlbumTrack(albumId);
        return this.apiClient.post(url, formData, null, abortSignal);
    }

    findAudioTrackAlbumSongs(filter, abortSignal = null) {
        const url = this.routeService.findAudioTrackAlbumSongs(filter);
        return this.apiClient.get(url, null, abortSignal);
    }

    findAlbums(filter, abortSignal = null) {
        const url = this.routeService.findAlbums(filter);
        return this.apiClient.get(url, null, abortSignal);
    }
}

export default NakiNigunAlbumService;
