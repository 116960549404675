import React from "react";
import { PropTypes } from "prop-types";
import { MrbCheckbox } from "mrb/components/checkbox";
import { PremiumBadge, Image } from "common/components";
import { MrbContentLoader } from "mrb/components/loader";
import { getContentActivityStatusIcon } from "application/home/utils";
import { useCanAccessContent, nakiRadioContentType } from "application/common/content-access";
import { HomeListLoader } from "application/home/components";
import { mergeCss } from "mrb/common/utils";
import { map, isNil } from "lodash";
import { podcastType, podcastContentType } from "common/constants";
import { observer } from "mobx-react";

function HomeFeaturedContentTemplate({ homeFeaturedContentViewStore, homeManageSelectedItemsViewStore, t }) {
    const {
        items,
        loaderStore,
        onClickFeaturedItem,
        isFeaturedScrollable,
        scrollFeaturedLeft,
        scrollFeaturedRight,
        setFeaturedContainerRef,
        goTo,
    } = homeFeaturedContentViewStore;
    const { isBulk } = homeManageSelectedItemsViewStore;

    return (
        <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <HomeListLoader row={1} bulk={isBulk} title />}>
            {() => (
                <React.Fragment>
                    {items.length > 0 && (
                        <div>
                            <div className="c-home__list__header">
                                <div className="u-type--base u-type--wgt--bold u-mar--bottom--tny">
                                    {t("APPLICATION.HOME.LIST.FEATURED")}
                                </div>
                                {isFeaturedScrollable && (
                                    <div className="u-display--flex">
                                        <i
                                            className="u-icon u-icon--base u-icon--arrow-left u-mar--right--tny"
                                            onClick={scrollFeaturedLeft}
                                        />
                                        <i
                                            className="u-icon u-icon--base u-icon--arrow-right"
                                            onClick={scrollFeaturedRight}
                                        />
                                    </div>
                                )}
                            </div>

                            <div className="u-animation--fadeIn">
                                <div className="c-home__list--scroll" ref={setFeaturedContainerRef}>
                                    {map(items, (item) => (
                                        <FeaturedItem
                                            item={item}
                                            key={item.stationId || item.podcastId}
                                            goTo={goTo}
                                            onClickFeaturedItem={onClickFeaturedItem}
                                            homeManageSelectedItemsViewStore={homeManageSelectedItemsViewStore}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </React.Fragment>
            )}
        </MrbContentLoader>
    );
}

HomeFeaturedContentTemplate.propTypes = {
    homeFeaturedContentViewStore: PropTypes.object.isRequired,
    homeManageSelectedItemsViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

const FeaturedItem = observer(function FeaturedItem({
    item,
    goTo,
    onClickFeaturedItem,
    homeManageSelectedItemsViewStore,
}) {
    const { isBulk, selectItem, selectedItems } = homeManageSelectedItemsViewStore;
    let contentType;
    if (!isNil(item.stationId)) {
        contentType = nakiRadioContentType.station;
    } else if (!isNil(item.podcastId)) {
        contentType = nakiRadioContentType.podcast;
    }

    const contentAccessResult = useCanAccessContent(contentType, {
        isPremium: item.isPremium,
        isLegacySubscriptionValid: item.validLegacySubscription,
        unavailableInMobileApp: item.isVisibleInApp === false,
    });
    return (
        <div
            className={mergeCss(
                "c-home__card",
                !isNil(item.stationId) ? "c-home__card--stations" : null,
                isBulk &&
                    (contentAccessResult.canAccess && item.podcastType !== podcastType.podcastGroup
                        ? "c-home__card--secondary"
                        : "c-home__card--tertiary")
            )}
        >
            {isBulk && contentAccessResult.canAccess && item.podcastType !== podcastType.podcastGroup && (
                <div className="c-home__bulk">
                    <MrbCheckbox
                        classNameExtend="c-input--check--tertiary"
                        onChange={() => {}}
                        value={
                            selectedItems.find(
                                (x) => x.stationId === item.stationId && x.podcastId === item.podcastId
                            ) !== undefined
                        }
                    />
                </div>
            )}
            <div
                className="c-home__card__media"
                onClick={() => {
                    isBulk ? selectItem(item) : onClickFeaturedItem(item);
                }}
            >
                <div
                    className={
                        contentAccessResult.canAccess && item.podcastContentType !== podcastContentType.torahAnytime
                            ? mergeCss("c-home__status", getContentActivityStatusIcon(item))
                            : null
                    }
                ></div>
                <div
                    className={mergeCss(
                        "c-home__card__img-wrapper",
                        contentAccessResult.canAccess &&
                            item.podcastType !== podcastType.podcastGroup &&
                            item.podcastContentType !== podcastContentType.torahAnytime
                            ? item.isTurnedOn
                                ? "status--disabled"
                                : "status--enabled"
                            : item.podcastType !== podcastType.podcastGroup &&
                              item.podcastContentType !== podcastContentType.torahAnytime
                            ? "status--off"
                            : null
                    )}
                >
                    <Image src={item.coverImageUrl} />
                    <PremiumBadge
                        isPremium={item.isPremium}
                        isPremiumSubscriptionValid={contentAccessResult.canAccessPremium}
                    />
                </div>
            </div>
            <div
                className="c-home__card__title"
                onClick={() => {
                    isBulk ? selectItem(item) : goTo(item);
                }}
            >
                {item.title}
            </div>
        </div>
    );
});

export default observer(HomeFeaturedContentTemplate);
