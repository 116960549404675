import { BaseService } from "mrb/core";
import { PodcastApiRouteService } from "common/services";
import { findParams } from "mrb/core/utils";

class PodcastService extends BaseService {
    constructor(apiClient) {
        super(apiClient, new PodcastApiRouteService());
    }

    findShufflablePodcast(filter, abortSignal = null) {
        const params = findParams(filter);
        const data = {
            embed: params.embed,
            excludeIds: params.excludeIds,
            fields: params.fields,
            page: params.page,
            rpp: params.rpp,
            searchQuery: params.searchQuery,
            sort: params.sort,
        };
        const url = this.routeService.findShufflablePodcast(data);
        return this.apiClient.post(url, data, null, abortSignal);
    }

    getAllEpisodes(id, options = {}, abortSignal = null) {
        const url = this.routeService.getAllEpisodes(id, options);
        return this.apiClient.get(url, null, abortSignal);
    }

    deletePodcastEpisode(id, abortSignal = null) {
        const url = this.routeService.deletePodcastEpisode(id);
        return this.apiClient.delete(url, null, null, abortSignal);
    }

    findEpisodes(podcastId, filter, abortSignal = null) {
        const url = this.routeService.findEpisodes(podcastId, filter);
        return this.apiClient.get(url, null, abortSignal);
    }

    updateActiveStatus(id, value, abortSignal = null) {
        const url = this.routeService.updateActiveStatus(id, value);
        return this.apiClient.put(url, null, null, abortSignal);
    }

    findDevicePodcasts(filter, abortSignal = null) {
        const url = this.routeService.findDevicePodcasts(filter);
        return this.apiClient.get(url, null, abortSignal);
    }

    updateOnByDefaultStatus(id, value, abortSignal = null) {
        const url = this.routeService.updateOnByDefaultStatus(id, value);
        return this.apiClient.put(url, null, null, abortSignal);
    }

    updateOnByDefaultInAppStatus(id, value, abortSignal = null) {
        const url = this.routeService.updateOnByDefaultInAppStatus(id, value);
        return this.apiClient.put(url, null, null, abortSignal);
    }

    updatePremiumStatus(id, value, abortSignal = null) {
        const url = this.routeService.updatePremiumStatus(id, value);
        return this.apiClient.put(url, null, null, abortSignal);
    }

    updatePodcastTurnedOnStatus(deviceId, podcastId, value, abortSignal = null) {
        const url = this.routeService.updatePodcastTurnedOnStatus(deviceId, podcastId, value);
        return this.apiClient.put(url, null, null, abortSignal);
    }

    updateVisibilityStatus(id, value, abortSignal = null) {
        const url = this.routeService.updateVisibilityStatus(id, value);
        return this.apiClient.put(url, null, null, abortSignal);
    }

    updateAppVisibilityStatus(id, value, abortSignal = null) {
        const url = this.routeService.updateAppVisibilityStatus(id, value);
        return this.apiClient.put(url, null, null, abortSignal);
    }

    updateRecentlyUpdatedVisibilityStatus(id, value, abortSignal = null) {
        const url = this.routeService.updateRecentlyUpdatedVisibilityStatus(id, value);
        return this.apiClient.put(url, null, null, abortSignal);
    }

    updateFeaturedStatus(id, value, abortSignal = null) {
        const url = this.routeService.updateFeaturedStatus(id, value);
        return this.apiClient.put(url, null, null, abortSignal);
    }

    createGroup(resource, abortSignal = null) {
        const url = this.routeService.createGroup(resource);
        return this.apiClient.post(url, resource, null, abortSignal);
    }

    updateGroup(resource, abortSignal = null) {
        const url = this.routeService.updateGroup(resource);
        return this.apiClient.put(url, resource, null, abortSignal);
    }

    updateHlsEnabledStatus(podcastId, value, abortSignal = null) {
        const url = this.routeService.updateHlsEnabledStatus(podcastId, value);
        return this.apiClient.put(url, null, null, abortSignal);
    }

    editPodcastEpisodeTitle(id, resources, abortSignal = null) {
        const url = this.routeService.editPodcastEpisodeTitle(id, resources);
        return this.apiClient.put(url, resources, null, abortSignal);
    }

    uploadEpisode(podcastId, formData, abortSignal = null) {
        const url = this.routeService.uploadEpisode(podcastId);
        return this.apiClient.post(url, formData, null, abortSignal);
    }

    findAudioTrackEpisodes(filter, abortSignal = null) {
        const url = this.routeService.findAudioTrackEpisodes(filter);
        return this.apiClient.get(url, null, abortSignal);
    }

    getPodcasts(filter, abortSignal = null) {
        const url = this.routeService.getPodcasts(filter);
        return this.apiClient.get(url, null, abortSignal);
    }
}

export default PodcastService;
