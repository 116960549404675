import { action, override, runInAction } from "mobx";
import { BaseHomePodcastViewStore } from "application/home/stores";
import { UpdatePodcastStatusOptions } from "application/common/models";
import { forEach, find, findIndex } from "lodash";

class HomePodcastGroupViewStore extends BaseHomePodcastViewStore {
    async get(id) {
        if (this.rootStore.userDeviceStore.selectedDevice) {
            return this.routeStore.getPodcastGroupWthDeviceSettings(
                id,
                this.rootStore.userDeviceStore.selectedDevice.id
            );
        }
        return this.routeStore.getPodcastGroup(id);
    }

    @action.bound
    async updateSubPodcastTurnedOnStatus(subPodcast, isTurnedOn) {
        try {
            this.statusToggleLoader.suspend();
            const options = new UpdatePodcastStatusOptions(
                subPodcast.id,
                subPodcast.title,
                subPodcast.isPremium,
                subPodcast.isVisibleInApp === false
            );
            await this.rootStore.userContentManagerStore.updatePodcastStatus(options, isTurnedOn);
            runInAction(() => {
                const subPodcastIndex = findIndex(this.item.subPodcasts, (item) => item.id === subPodcast.id);
                this.item.subPodcasts[subPodcastIndex].userDevicePodcast = { isTurnedOn: isTurnedOn };
            });
        } catch (err) {
            this.rootStore.notificationStore.error("Unexpected error occurred. Please contact the support team.", err);
        } finally {
            this.statusToggleLoader.resume();
        }
    }

    getStatusItem(deviceId, resourceId) {
        return this.routeStore.getPodcastGroupUserDevicePodcast(deviceId, resourceId);
    }

    @override
    setStatusItem(statusItem) {
        this.statusItem = statusItem.podcastGroup;
        forEach(this.item.subPodcasts, (subPodcast) => {
            if (statusItem.subPodcasts && statusItem.subPodcasts.length > 0) {
                subPodcast.userDevicePodcast = find(statusItem.subPodcasts, (item) => item.podcastId === subPodcast.id);
            } else {
                subPodcast.userDevicePodcast = null;
            }
        });
    }
}

export default HomePodcastGroupViewStore;
